import styled from '@emotion/styled';
import { breakpoints, mediaMin } from 'styles';

export const Header = styled.header`
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 14px;

  position: relative;
  z-index: 2;

  ${mediaMin(breakpoints.tabletL)} {
    padding-top: 25px;
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

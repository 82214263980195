import { useEffect, useState, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { breakpoints, mediaMax } from 'styles';

type useCheckMobileScreenValue = {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  height: number;
  width: number;
};

type UseCheckScreenWidth = (options?: {
  mobileWidth?: number;
  tableWidth?: number;
  laptopWidth?: number;
}) => useCheckMobileScreenValue;

/**
 *
 * @description Hook to detect is it mobile screen
 *
 * @type {useCheckMobileScreenValue}
 *
 */

// TODO change resize and use https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList

export const useCheckMobileScreen: UseCheckScreenWidth = ({
  mobileWidth,
  tableWidth,
  laptopWidth,
} = {}): useCheckMobileScreenValue => {
  const isClient = typeof window === 'object';
  const [width, setWidth] = useState(isClient ? window.innerWidth : 0);
  const [height, setHeight] = useState(isClient ? window.innerHeight : 0);

  const isMobile = useMediaQuery(isClient ? mediaMax(mobileWidth || breakpoints.tabletXS) : '');
  const isTablet = useMediaQuery(isClient ? mediaMax(tableWidth || breakpoints.tabletM) : '');
  const isLaptop = useMediaQuery(isClient ? mediaMax(laptopWidth || breakpoints.laptopS) : '');

  useEffect(() => {
    if (!isClient) return undefined;

    const handleWindowSizeChange = (): void => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isClient]);

  const value = useMemo(() => {
    return {
      isMobile,
      isTablet,
      isLaptop,
      height,
      width,
    };
  }, [height, isLaptop, isMobile, isTablet, width]);

  return value;
};

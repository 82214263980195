import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';
import { breakpoints, mediaMax, COLORS } from 'styles';

export const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: var(--form-container-padding, 10px 6px 0 0);
  justify-content: flex-start;

  ${mediaMax(breakpoints.builder.lg)} {
    padding: var(--form-container-padding, 14px 20px 60px 20px);
    gap: 16px;
  }
  ${mediaMax(breakpoints.mobileL)} {
    padding: 14px 20px 29px 20px;
  }
`;
FormContainer.displayName = 'FormContainer';

export const FormBlockTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;

  &:first-child {
    padding-top: 0;
  }

  ${mediaMax(breakpoints.builder.md)} {
    padding-bottom: 15px;
  }
`;
FormBlockTitle.displayName = 'FormBlockTitle';

export const FormFieldLabel = styled.span`
  color: ${COLORS.PRIMARY_TEXT};
  font-weight: 500;
  padding-bottom: 4px;
`;
FormFieldLabel.displayName = 'FormFieldLabel';

export const OneColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
  row-gap: 14px;

  &.small-margin {
    margin-bottom: 24px;
  }

  ${mediaMax(breakpoints.builder.md)} {
    grid-template-columns: 1fr;
    margin-bottom: 24px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;
OneColumnContainer.displayName = 'OneColumnContainer';

export const TwoColumnContainer = styled(OneColumnContainer)`
  grid-template-columns: 1fr 1fr;
  column-gap: 62px;

  ${mediaMax(breakpoints.builder.md)} {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;
TwoColumnContainer.displayName = 'TwoColumnContainer';

export const ContainerFlexAlignCenterJustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  aling-items: center;
`;
ContainerFlexAlignCenterJustifyBetween.displayName = 'ContainerFlexAlignCenterJustifyBetween';

export const ContainerFlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;
ContainerFlexAlignCenter.displayName = 'ContainerFlexAlignCenter';

export const AccordionTitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  ${mediaMax(breakpoints.builder.lg)} {
    gap: 0;
  }
`;

export const AccordionTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;

  &[data-disabled='true'] {
    color: ${COLORS.INACTIVE_TEXT};
  }
`;
AccordionTitle.displayName = 'AccordionTitle';

export const AccordionSubtitle = styled.p`
  color: ${COLORS.SECONDARY_TEXT};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;

  &[data-disabled='true'] {
    color: ${COLORS.INACTIVE_TEXT};
  }
`;
AccordionSubtitle.displayName = 'AccordionSubtitle';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  border-bottom: 1px solid ${COLORS.FORM_ELEMENT_BORDER};
  margin-bottom: 39px;

  ${mediaMax(breakpoints.builder.md)} {
    margin-bottom: 24px;
  }

  &.small-margin {
    margin-bottom: 24px;

    ${mediaMax(breakpoints.builder.md)} {
      margin-bottom: 15px;
    }
  }

  &.with-margin-top {
    margin-top: 40px;

    ${mediaMax(breakpoints.builder.md)} {
      margin-top: 24px;
    }

    &.small-margin {
      margin-top: 24px;

      ${mediaMax(breakpoints.builder.md)} {
        margin-top: 15px;
      }
    }
  }
`;
Separator.displayName = 'Separator';

export const InputAndLabelGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;

  &.justify-center {
    justify-content: center;
  }
`;
InputAndLabelGroup.displayName = 'InputAndLabelGroup';

export const FlexRow = styled.div`
  display: flex;
  column-gap: 30px;
  justify-content: flex-start;
`;
FlexRow.displayName = 'FlexRow';

export const SocialNetworkInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  ${mediaMax(breakpoints.builder.md)} {
    gap: 8px;
    margin-bottom: 15px;
  }
`;
SocialNetworkInputsContainer.displayName = 'SocialNetworkInputsContainer';

export const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  pointer-events: none;
  margin: 0;

  align-content: center;

  &.no-padding {
    gap: 12px;

    .MuiCheckbox-root {
      padding: 0;
    }
  }

  .MuiCheckbox-root {
    pointer-events: auto;
  }

  .MuiTypography-root {
    font-weight: 400;
    line-height: 1.3;
  }
`;

export const LabelWhitespaceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 50px;
`;
LabelWhitespaceContainer.displayName = 'LabelWhitespaceContainer';

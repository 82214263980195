import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LangAccordion,
  LangSelect,
  LangSelectContainer,
  LangSummary,
  LangTitle,
  RenderValue,
  TitleContainer,
} from './styles';
import { useRouter } from 'next/router';
import ExpandLang from 'components/ui/icons/UI/LangSelect/ExpandLang';
import GlobeIcon from 'components/ui/icons/UI/LangSelect/GlobeIcon';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LangOption from './LangOption';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { changeLanguage } from 'config/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailableNativeLanguages } from 'store/account/selectors';
import { Language } from 'types/account';
import { RootState } from 'store/index';
import { checkNativeLanguages } from 'store/account';

export enum LanguagePickerVariant {
  Select = 'select',
  Dropdown = 'dropdown',
}

type LanguageSelectProps = {
  onChange?: (locale: string) => void;
  isCompact?: boolean;
  pickerType?: LanguagePickerVariant;
};

export const getLanguageNativeNames = (langCode: string, nativeLanguages: Language[]) => {
  return nativeLanguages?.filter((l) => l.code === langCode)[0]?.name ?? `${langCode}.`;
};

export const useLanguageSelector = () => {
  const { i18n } = useTranslation();
  const { language: currentLanguage } = i18n;
  const router = useRouter();
  const locales = router.locales ?? [currentLanguage];
  const nativeLanguages = useSelector(selectAvailableNativeLanguages);
  const nativeLanguagesFetched = useSelector((state: RootState) => state.account.nativeLanguagesFetched);
  const languagesSorted = useMemo(() => {
    return locales
      .map((lang) => ({ code: lang, name: getLanguageNativeNames(lang, nativeLanguages) }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [locales, nativeLanguages]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!nativeLanguagesFetched) {
      dispatch(checkNativeLanguages());
    }
  }, [nativeLanguagesFetched, dispatch]);

  const switchToLocale = useCallback(
    (locale: string) => {
      changeLanguage(locale);

      window?.dataLayer?.push({
        event: 'language_filter',
        previous_language: getLanguageNativeNames(i18n.language, nativeLanguages),
        selected_language: getLanguageNativeNames(locale, nativeLanguages),
      });

      return router.push(router.asPath, router.asPath, { locale });
    },
    [i18n.language, nativeLanguages, router]
  );

  return {
    switchToLocale,
    locales,
    value: i18n.language,
    nativeLanguages,
    languagesSorted,
  };
};

const LanguageSelect: FC<LanguageSelectProps> = ({
  onChange,
  isCompact = false,
  pickerType = LanguagePickerVariant.Select,
}) => {
  const { switchToLocale, locales, nativeLanguages, languagesSorted } = useLanguageSelector();
  const { i18n } = useTranslation();
  const { language: currentLanguage } = i18n;
  const { isMobile } = useCheckMobileScreen();
  const [isExpanded, setIsExpanded] = useState(false);

  const mobileSpacing = isMobile ? 140 : 125;
  const modifyLangName = useMemo(
    () =>
      isCompact ? currentLanguage.toLocaleUpperCase() : nativeLanguages.find((l) => l.code === i18n.language)?.name,
    [isCompact, currentLanguage, nativeLanguages, i18n.language]
  );

  const languageChanged = useCallback(
    async (locale: string) => {
      if (pickerType === LanguagePickerVariant.Dropdown) setIsExpanded(false);

      if (onChange) {
        onChange(locale);
      }

      await switchToLocale(locale);
    },
    [pickerType, onChange, switchToLocale]
  );

  if (pickerType === LanguagePickerVariant.Dropdown) {
    return (
      <LangAccordion
        expanded={isExpanded}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        slotProps={{
          transition: {
            style: {
              maxHeight: 164,
              overflowY: 'auto',
            },
          },
        }}
      >
        <LangSummary expandIcon={<ExpandLang color="#1E1D20" />}>
          <TitleContainer>
            <GlobeIcon />
            <LangTitle>{getLanguageNativeNames(i18n.language, nativeLanguages)}</LangTitle>
          </TitleContainer>
        </LangSummary>
        {languagesSorted.map((locale) => {
          return (
            <LangOption
              key={locale.code}
              onClick={() => {
                languageChanged(locale.code);
              }}
              locale={locale.code}
              label={locale.name}
            />
          );
        })}
      </LangAccordion>
    );
  }

  return (
    <LangSelectContainer>
      <LangSelect
        labelId="lang-select-label"
        id="lang-select"
        value={i18n.language}
        onChange={(e: SelectChangeEvent<unknown>) => {
          languageChanged(e.target.value as string);
        }}
        IconComponent={isCompact ? ExpandMoreIcon : ExpandLess}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            horizontal: -10,
            vertical: isCompact ? -10 : mobileSpacing,
          },
          PaperProps: {
            style: {
              maxHeight: 206,
              overflowY: 'auto',
            },
          },
        }}
        renderValue={() => (
          <RenderValue>
            <GlobeIcon />
            {modifyLangName}
          </RenderValue>
        )}
      >
        {languagesSorted.map(({ code, name }) => {
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </LangSelect>
    </LangSelectContainer>
  );
};

export default memo(LanguageSelect);

import styled from '@emotion/styled';

export const LogoImage = styled.img<{ footerLogo?: boolean }>`
  width: 150px;
  height: 28px;

  ${(props) =>
    props.footerLogo &&
    `
      width: 214px;
      height: 40px;
  `}
`;

export const Link = styled.a``;

import { FC, memo, PropsWithChildren, ReactNode } from 'react';
import { ActionsContainer, Header } from './styled';
import LogoSSR from '../components/LogoSSR';
import LanguageSelect from 'components/LanguageSelect';
import Hidden from 'components/ui/Hidden';

type MainHeaderProps = {
  children?: ReactNode;
  linkTo: string;
  showLanguagePicker?: boolean;
};

const MainHeader: FC<PropsWithChildren<MainHeaderProps>> = ({ children, linkTo, showLanguagePicker = true }) => {
  return (
    <Header>
      <LogoSSR linkTo={linkTo} />
      <ActionsContainer>
        {children}
        {showLanguagePicker ? (
          <Hidden mdDown>
            <LanguageSelect isCompact />
          </Hidden>
        ) : null}
      </ActionsContainer>
    </Header>
  );
};

export default memo(MainHeader);

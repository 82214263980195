import Link from 'next/link';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';

import { LINKS } from 'constants/constants';

import { LogoImage } from './styled';
import useIsCheckoutPage from 'hooks/useIsCheckoutPage';

const logo = '/myqrcode-logo.svg';

interface LogoProps {
  footerLogo?: boolean;
  linkTo?: string;
  reactRouterLink?: boolean;
  reload?: boolean;
}

const Logo: FC<LogoProps> = ({
  footerLogo,
  linkTo = LINKS.CABINET_QR_CODES,
  reactRouterLink = false,
  reload = false,
}) => {
  const { t } = useTranslation();

  const isCheckoutPage = useIsCheckoutPage();

  const logoDimensions = footerLogo ? { width: 214, height: 40 } : { width: 150, height: 28 };

  const logoImageComponent = (
    <LogoImage
      footerLogo={footerLogo}
      src={logo}
      alt={t('qr.page.landing.seo.logo.alt', 'QR code generator myQRcode')}
      draggable="false"
      width={logoDimensions.width}
      height={logoDimensions.height}
      loading={footerLogo ? 'lazy' : undefined}
    />
  );

  if (isCheckoutPage) {
    return logoImageComponent;
  }

  if (reactRouterLink) {
    return (
      <ReactRouterLink to={linkTo} reloadDocument={reload}>
        {logoImageComponent}
      </ReactRouterLink>
    );
  }

  return <Link href={linkTo}>{logoImageComponent}</Link>;
};

export default memo(Logo);

import Link from 'next/link';
import styled from '@emotion/styled';
import { COLORS, mediaMax, mediaMin, breakpoints, mediaMinMax } from 'styles';

type ContentProps = {
  showFooter?: boolean;
  noBGImage?: boolean;
  backgroundColor?: string;
  heightAuto?: boolean;
};

type LandingContainerProps = {
  keepWidth?: boolean;
};

type PopUpMenuContainerProps = {
  isPopUpOpen?: boolean;
};

export const Container = styled.section<ContentProps>`
  ${({ heightAuto }) => (heightAuto ? `min-height: unset;` : `min-height: 99.9vh;`)};

  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
  ${({ noBGImage }) => (noBGImage ? `background: ${COLORS.MAIN_BACKGROUND};` : `background: ${COLORS.WHITE}`)}
  ${({ noBGImage }) =>
    noBGImage
      ? 'min-height: calc(100vh - 250px);'
      : `min-height: ${({ showFooter }: ContentProps) =>
          showFooter ? 'calc(100vh - 130px);' : 'calc(100vh - 73px);'}`}
    ${mediaMax(breakpoints.tabletXS)} {
    ${({ noBGImage }) => (noBGImage ? 'min-height: 685px;' : '')};
  }
  ${mediaMax(820)} {
    min-height: unset;
  }
`;

export const ContentContainer = styled.div`
  max-width: 1196px;
  margin: auto;

  ${mediaMinMax(breakpoints.tabletL, breakpoints.laptopS - 1)} {
    width: 968px;
  }

  ${mediaMax(breakpoints.tabletL - 1)} {
    width: 100%;
    padding: 0 20px;
  }

  ${mediaMax(breakpoints.tabletXS)} {
    //width: 375px;
  }
  ${mediaMax(breakpoints.mobileL)} {
    //width: 100%;
  }
`;

export const LandingContainer = styled.div`
  width: 100%;
`;

export const Content = styled.section`
  box-sizing: border-box;
  width: 100%;

  ${({ keepWidth }: LandingContainerProps) =>
    !keepWidth &&
    `${mediaMinMax(breakpoints.tabletL, breakpoints.laptopS - 1)} {
    min-width: 968px;
    padding: 0;
    }
    ${mediaMax(breakpoints.tabletL - 1)} {

      margin: 0 auto;
    }
    ${mediaMax(428)} {
      width: 100%;
    }
  `}
`;

export const LandingBlueBlock = styled.section`
  ${mediaMin(breakpoints.laptopS)} {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

export const LoginButton = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: var(--qr-global-color-blue);
  display: flex;
  min-width: 112px;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  color: var(--qr-global-color-white);
  padding: 0px 16px;

  &.menuLoginButton {
    height: 40px;
    width: 100%;
    margin-top: 20px;
  }

  &:hover {
    color: var(--qr-global-color-white);
    background-color: var(--qr-global-color-blue-hover);
  }

  ${mediaMax(breakpoints.tabletL)} {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
`;

export const PopUpWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  transition: 0.3s ease all;

  &.popupTransition {
    &-enter-done,
    &-appear-done {
      opacity: 1;
      transition: 0.3s ease all;
    }

    &-exit-active {
      opacity: 0;
      transition: 0.3s ease all;
    }
  }
`;

export const PopUpBackground = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const PopUpHeader = styled.div`
  display: flex;
  max-width: 100%;
  padding: 15px 20px 16px;
  justify-content: space-between;

  ${mediaMin(breakpoints.mobileL)} {
    max-width: 375px;
  }

  > span {
    margin-right: -6px !important;
  }
`;

export const PopUpContent = styled.div`
  height: calc(100% - 90px);
  overflow-y: auto;
  padding: 0 20px;
`;

export const PopUpContainer = styled.div`
  max-width: ${breakpoints.mobileMS}px;
  height: 100%;
  overflow: hidden;
`;

export const PopUpMenuContainer = styled.div<PopUpMenuContainerProps>`
  background: var(--qr-global-color-white);
  height: 100%;
  transform: translateX(-100%);
  transition: 0.3s ease all;

  &.menuTransition {
    &-enter-done,
    &-appear-done {
      transform: translateX(0);
      transition: 0.3s ease all;
    }

    &-exit-done {
      transform: translateX(-100%);
      transition: 0.3s ease all;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background: var(--qr-global-color-white);
    background: white;
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  ${mediaMin(breakpoints.mobileSM)} {
    width: calc(100% - 66px);
  }
`;

export const PopUpMenu = styled.menu`
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  flex-flow: column;
  padding: 0;

  ${mediaMin(breakpoints.mobileL)} {
    max-width: 375px;
  }
`;

export const MenuLinkWrapper = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const MenuLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  min-height: 50px;
  line-height: 20px;
  color: var(--qr-global-color-text-primary);
  position: relative;
  user-select: none;

  &::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.042' height='8.818' viewBox='0 0 5.042 8.818'%3E%3Cpath d='M10.6,14.768l3.334-3.337a.627.627,0,0,1,.89,0,.635.635,0,0,1,0,.893L11.044,16.1a.629.629,0,0,1-.869.018l-3.8-3.8a.63.63,0,0,1,.89-.893Z' transform='translate(-11.246 15.005) rotate(-90)' fill='%23424a5c'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 6px;
    height: 10px;
  }
`;

import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import { COLORS } from 'styles';

export const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    margin: 0;
  }

  &::before {
    display: none;
  }

  &.MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0px 1px 15px rgba(12, 37, 54, 0.15);
    border: 1px solid transparent;
  }

  &.MuiPaper-root[data-error='true'] {
    border: 1px solid ${COLORS.RED500};
  }

  &.Mui-disabled {
    background: #ffffff;
  }
`;

export const Container = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
